<template>
    <div class="row">
        <div class="col-md-12">
            <button style="" @click="$router.push('src')" class="btn btn-primary">Add</button>
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Description</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="sr in Srs" :key="sr.key">
                        <td>{{ sr.name }}</td>
                        <td>{{ sr.notes }}</td>
                        <td>
                            <router-link :to="{name: 'SREdit', params: { id: sr.key }}" class="btn btn-primary">Edit
                            </router-link>
                            <button @click.prevent="deleteSr(sr.key)" class="btn btn-danger">Delete</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import { db } from '../firebaseDb';
    export default {
        data() {
            return {
                Srs: [],
                currentTenant: "",
                service: {},
                serviceid: "",
            }
        },
        
        created() {
            this.currentTenant = this.$tenant;
            this.updateservices();
        },
        methods: {
            updateservices() {
                db.collection('srs').orderBy('name').onSnapshot((snapshotChange) => {
                this.Srs = [];
                snapshotChange.forEach((doc) => {
                    if(doc.data().tenant == this.currentTenant && doc.data().deleted !== true) {
                    this.Srs.push({
                        key: doc.id,
                        name: doc.data().name,
                        notes: doc.data().notes,
                    })
                    }
                });
            })
            },
            deleteSr(id){
              if (window.confirm("Do you really want to delete?")) {
                  db.collection('services').onSnapshot((snapshotChange) => {
                snapshotChange.forEach((doc) => {
                    this.service = doc.data();
                        this.serviceid = doc.id;
                    if(doc.data().srs.find(x => x.name === this.Srs.find(x => x.key === id).name) != null) {
                        this.service.srs.splice(this.service.srs.map((el) => el.name).indexOf(this.Srs.find(x => x.key === id)).name, 1)
                        db.collection('services').doc(this.serviceid)
                        .update(this.service).then(() => {
                            console.log("Service successfully updated!");
                        }).catch((error) => {
                            console.log(error);
                        });
                    }
                })
                });
                this.newCem = this.Srs.find(x => x.key === id)
                console.log(this.newCem)
                this.newCem.deleted = true;
                db.collection("srs").doc(id).update(this.newCem).then(() => {
                    console.log("Cemetery deleted!");
                })
                .catch((error) => {
                    console.error(error);
                })
              }
            }
        }
    }
</script>

<style>
    .btn-primary {
        margin-right: 12px;
    }
</style>